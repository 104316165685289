<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-input
          v-model="searchForm.search"
          :placeholder="$t('单据编号, 往来单位单号, 备注')"
          allowClear
          @pressEnter="search"
        />
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
      </a-col>

      <a-col :span="24" :md="12" :xl="12" style="margin-bottom: 12px; width: 400px">
        <a-button-group>
          <a-button @click="printPickingRecord">{{ $t("打印拣货记录") }}</a-button>
        </a-button-group>
      </a-col>
    </a-row>

    <div>
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="items"
        :pagination="pagination"
        :loading="loading"
        @change="onChangeTable"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :scroll="{ x: 1440 }"
      >
        <template slot="picking_order_number" slot-scope="value, item, index">
          <a @click="handleDetail(item.id)">{{ value }}</a>
        </template>
        <div slot="action" slot-scope="value, item, index">
          <a-button-group size="small">
            <a-button @click="handleDetail(item.id)">{{ $t("详情") }}</a-button>
            <a-popconfirm :title="$t('确定作废吗?')" @confirm="voidItem(item)">
              <a-button type="danger">{{ $t("作废") }}</a-button>
            </a-popconfirm>
          </a-button-group>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { pickingRecordList, pickingRecordVoid, pickingRecordPrint } from "@/api/stockOut";
import { columns } from "./columns";
import moment from "moment";
import lodopPrintPickingRecord from "@/views/printConfigs/printPickingRecord";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1, is_void: false },
      loading: false,
      items: [],

      selectedRowKeys: [],
      selectionRows: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
    },
    handleDetail(id) {
      this.$router.push({ path: "/stock_out/picking_record_detail", query: { id } });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      pickingRecordList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    voidItem(item) {
      pickingRecordVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    printPickingRecord() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的拣货记录!"));
        return;
      }

      pickingRecordPrint({ ids: this.selectedRowKeys }).then((data) => {
        lodopPrintPickingRecord(data, {
          title: "拣货记录",
          headerFields: ["client", "warehouse", "external_number", "creator", "create_time"],
          tableFields: ["material_number", "material_name", "batch_number", "location_number", "total_quantity"],
          tableRows: 10,
          tableSortType: "positive",
          tableSortField: "total_quantity",
        });
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
